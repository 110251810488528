import { useState } from 'react'
import logo from './../assets/images/logo/logo.png'
import logo1 from './../assets/images/logo/logo1.png'
import { useLocation  } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
export default function Header({ navigation }) {
    const location = useLocation();
    //const [menuOpen, setMenuOpen] = useState(false)
    const [state, setState] = useState(false)

    // Replace javascript:void(0) paths with your paths
    const nav = [
        { title: "Accueil", path: "/" },
        { title: "A propos", path: "/about" },
        { title: "Services", path: "/services" },
        //{ title: "Portfolio", path: "/#portfolio" },
        { title: "Contact", path: "/contact" }
    ]

    return (
        <nav className="w-full bg-white border-b md:static md:text-sm md:border-none shadow-bottom">
        <div className="items-center max-w-screen-xl px-4 mx-auto md:flex md:px-8">
            <div className="flex items-center justify-between py-3 md:py-5 md:block">
                <Link to="/" className="flex items-center justify-between">
                    <img
                        src={logo}
                        className="logo-img-head"
                        alt="Float UI logo"
                    />
                    <div className="logo-text ">
                        <span>Congo Digital</span>
                        <span className="mt-1">Services</span>
                    </div>
                </Link>
                <div className="md:hidden">
                    <button className="text-gray-500 hover:text-gray-800"
                        onClick={() => setState(!state)}
                    >
                        {
                            state ? (
                                <svg xmlns="http://www.w3.org/2000/svg" className="btn-menu" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="btn-menu">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                </svg>
                            )
                        }
                    </button>
                </div>
            </div>
            <div className={`flex-1 pb-3 mt-8 md:block md:pb-0 md:mt-0 ${state ? 'block' : 'hidden'}`}>
                <ul className="items-center justify-end space-y-6 md:flex md:space-x-6 md:space-y-0">
                    {
                        nav.map((item, idx) => {
                            return (
                                <li key={idx} className={`nav-item ${location.pathname === item.path ? 'active' : ''}`}>
                                    <Link smooth to={item.path} className="block">
                                        {item.title}
                                    </Link>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    </nav>
    )
}
