import React from 'react';
import { motion } from 'framer-motion';
import { HashLink as Link } from 'react-router-hash-link';

const services = [
    {
        title: "Développement Web et Mobile",
        path: "/services/web-mobile-development/#top"
    },
    {
        title: "Développement d'Infrastructures réseaux",
        path: "/services/network-infrastructure/#top"
    },
    {
        title: "Sécurité web et réseaux",
        path: "/services/web-network-security/#top"
    },
    {
        title: "Marketing Digital",
        path: "/services/digital-marketing/#top"
    },
    {
        title: "Maintenance et Support",
        path: "/services/maintenance-support/#top"
    },
    {
        title: "Archivage numérique",
        path: "/services/digital-archiving/#top"
    },
    {
        title: "Formation et Développement des Compétences",
        path: "/services/digital-skills-development/#top"
    },
    {
        title: "Recrutement et Promotion des Emplois",
        path: "/services/recruitment-promotion/#top"
    },
    {
        title: " Renforcement de l’Écosystème Numérique",
        path: "/services/ecosystem-strengthening/#top"
    },
];
export default function NavServices (){
    return(
        <motion.div
                        className="p-6 bg-white rounded-lg shadow-lg staggeredFadeInUp"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <h2 className="text-2xl font-bold text-first-color">Services Récents</h2>
                        <ul className="mt-4 space-y-2">
                            {services.map((service, index) => (
                                <li key={index}>
                                    <Link to={service.path} smooth className="text-first-color hover:text-second-color">{service.title}</Link>
                                </li>
                            ))}
                        </ul>
                    </motion.div>
    )
}