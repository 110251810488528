import React from 'react';
import './../styles/loader.css'; // Assurez-vous de créer un fichier CSS correspondant pour le style

export default function Loader() {
    return (
        <div className="loader-container">
            <div className="spinner"></div>
        </div>
    );
}
