import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboardTeacher, faGraduationCap, faHandsHelping } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import NavServices from './NavServices';

export default function DigitalSkillsDevelopment() {
    return (
        <>
            {/* Hero Section */}
            <motion.div 
                className="relative flex items-center justify-center h-64 bg-center bg-cover" 
                style={{ backgroundImage: "url('https://example.com/formation-developpement.jpg')" }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <h1 className="relative text-4xl font-bold text-white">Formation et Développement des Compétences</h1>
            </motion.div>

            {/* Service Overview Section */}
            <div className="container px-4 py-12 mx-auto">
                <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
                    <NavServices/>
                    <motion.div 
                        className="md:col-span-2 staggeredFadeInUp" 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <h2 className="text-3xl font-bold">Aperçu du Service</h2>
                        <p className="mt-4 text-gray-600">
                            Nous offrons des Programmes de Formation TIC de pointe, en collaboration avec des établissements d'enseignement pour intégrer des Curricula Numériques axés sur le Développement Durable. Notre mission est de préparer les Congolais à exceller dans l'économie numérique, en les dotant des compétences nécessaires pour répondre aux défis de demain.
                        </p>
                        <div className="grid grid-cols-1 gap-4 mt-8 md:grid-cols-2">
                            <img src="https://plus.unsplash.com/premium_photo-1681400690940-8eff232a8f7d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Programme de Formation" className="object-cover w-full h-48 rounded-lg"/>
                            <img src="https://plus.unsplash.com/premium_photo-1681400690940-8eff232a8f7d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Développement des Compétences" className="object-cover w-full h-48 rounded-lg"/>
                        </div>
                    </motion.div>
                </div>
            </div>

            {/* Service Type Section */}
            <div className="container px-4 py-12 mx-auto">
                <h2 className="text-3xl font-bold text-center">Types de Services</h2>
                <div className="grid grid-cols-1 gap-8 mt-8 md:grid-cols-2">
                    <motion.div 
                        className="flex items-center p-6 bg-white rounded-lg shadow-lg staggeredFadeInUp" 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <FontAwesomeIcon icon={faChalkboardTeacher} size="2x" className="text-first-color"/>
                        <div className="ml-4">
                            <h3 className="text-xl font-bold">Programmes de Formation TIC</h3>
                            <p className="text-gray-600">Des cours spécialisés pour améliorer les compétences numériques et préparer les étudiants aux exigences du marché du travail moderne.</p>
                        </div>
                    </motion.div>
                    <motion.div 
                        className="flex items-center p-6 bg-white rounded-lg shadow-lg staggeredFadeInUp" 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <FontAwesomeIcon icon={faGraduationCap} size="2x" className="text-first-color"/>
                        <div className="ml-4">
                            <h3 className="text-xl font-bold">Curricula Numériques</h3>
                            <p className="text-gray-600">Intégration de contenus numériques dans les programmes éducatifs pour favoriser le développement durable et l'innovation.</p>
                        </div>
                    </motion.div>
                    <motion.div 
                        className="flex items-center p-6 bg-white rounded-lg shadow-lg staggeredFadeInUp" 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <FontAwesomeIcon icon={faHandsHelping} size="2x" className="text-first-color"/>
                        <div className="ml-4">
                            <h3 className="text-xl font-bold">Partenariats avec les Établissements d'Enseignement</h3>
                            <p className="text-gray-600">Collaboration avec des institutions académiques pour offrir des programmes d'apprentissage pratiques et pertinents.</p>
                        </div>
                    </motion.div>
                </div>
            </div>

            {/* FAQ Section */}
            <div className="container px-4 py-12 mx-auto">
                <h2 className="text-3xl font-bold text-center">Questions Fréquemment Posées</h2>
                <div className="mt-8 space-y-4">
                    <motion.div className="p-4 bg-white rounded-lg shadow-lg staggeredFadeInUp" whileHover={{ scale: 1.01 }}>
                        <h3 className="text-xl font-bold text-first-color">Quels sont les avantages des programmes de formation TIC ?</h3>
                        <p className="mt-2 text-gray-600">Nos programmes de formation TIC visent à améliorer les compétences numériques, augmentant ainsi les opportunités d'emploi et la capacité à innover dans divers secteurs.</p>
                    </motion.div>
                    <motion.div className="p-4 bg-white rounded-lg shadow-lg staggeredFadeInUp" whileHover={{ scale: 1.01 }}>
                        <h3 className="text-xl font-bold text-first-color">Quels types de certifications offrez-vous ?</h3>
                        <p className="mt-2 text-gray-600">Nous proposons des certifications reconnues qui valident les compétences acquises et augmentent la crédibilité professionnelle des participants.</p> 
                    </motion.div>
                    <motion.div className="p-4 bg-white rounded-lg shadow-lg staggeredFadeInUp" whileHover={{ scale: 1.01 }}>
                        <h3 className="text-xl font-bold text-first-color">Comment s'inscrire à vos programmes de formation ?</h3>
                        <p className="mt-2 text-gray-600">Les inscriptions se font en ligne via notre plateforme, avec un accompagnement disponible pour aider à choisir le programme le plus adapté.</p>
                    </motion.div>
                </div>
            </div>
        </>
    );
}
