import { useState } from 'react';
import logo from './../assets/images/logo/logo.png';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

export default function Footer({ navigation }) {
    const location = useLocation();
    const [state, setState] = useState(false);

    const scrollToTop = () => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 100); // 100ms delay to ensure smooth scrolling
    };

    return (
        <>
            <footer className="bg-[#1A1D2B]">
                <div className="container p-0 mx-auto md:p-8 xl:px-0">
                    <div className="px-6 pt-16 pb-10 mx-auto max-w-7xl">
                        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                            <div className="space-y-4">
                                <div>
                                    <Link to="/#top" smooth>
                                        <div className="flex items-center space-x-2 text-2xl font-medium">
                                            <span>
                                                <img src={logo} alt="AI Logo"
                                                    width="64" height="64" className="w-16" />
                                            </span>
                                            <span className="text-white">Congo Digital</span>
                                        </div>
                                    </Link>
                                </div>
                                <div className="max-w-md pr-16 text-gray-200 text-md">
                                    Le partenaire privilégié pour transcender votre présence digitale, en offrant des solutions sur mesure qui marient élégance, fonctionnalité et innovation.
                                </div>
                                <div className="flex space-x-2">
                                    <a href="https://www.linkedin.com/company/congo-digital-services/" target="_blank" rel="noopener noreferrer" className="text-gray-200 hover:text-gray-200">
                                        <span className="sr-only">Linkedin</span><svg fill="currentColor" viewBox="0 0 24 24" className="w-6 h-6" aria-hidden="true">
                                            <path fillRule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clipRule="evenodd"></path>
                                        </svg>
                                    </a>
                                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-gray-200 hover:text-gray-200">
                                        <span className="sr-only">Twitter</span><svg fill="currentColor" viewBox="0 0 24 24" className="w-6 h-6" aria-hidden="true">
                                            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-8 mt-16 xl:col-span-2 xl:mt-0">
                                <div className="md:grid md:grid-cols-2 md:gap-8">
                                    <div>
                                        <h3 className="font-semibold leading-6 text-white text-md">Nos services</h3>
                                        <ul role="list" className="mt-6 space-y-4">
                                            <li>
                                                <Link to="/services/web-mobile-development/#top" smooth className="leading-6 text-gray-300 text-md hover:text-gray-50">
                                                    Developpement Web & Mobile
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/services/digital-marketing/#top" smooth className="leading-6 text-gray-300 text-md hover:text-gray-50">
                                                    Marketing Digital
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/services/network-infrastructure/#top" smooth className="leading-6 text-gray-300 text-md hover:text-gray-50">
                                                    Développement d'Infrastructures réseaux
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="md:mt-0">
                                        <h3 className="texte-h-24 texte-h-24-none"></h3>
                                        <ul role="list" className="mt-6 space-y-4">
                                            <li>
                                                <Link to="/services/digital-archiving/#top" smooth className="leading-6 text-gray-300 text-md hover:text-gray-50">
                                                    Archives numériques
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/services/maintenance-support/#top" smooth className="leading-6 text-gray-300 text-md hover:text-gray-50">
                                                    Maintenance et support
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/services/web-network-security/#top" smooth className="leading-6 text-gray-300 text-md hover:text-gray-50">
                                                    Sécurité web et réseaux
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="md:grid md:grid-cols-2 md:gap-8">
                                    <div>
                                        <h3 className="font-semibold leading-6 text-white text-md">Ressources</h3>
                                        <ul role="list" className="mt-6 space-y-4">
                                            {/* <li>
                                                <Link to="/blog" smooth className="leading-6 text-gray-300 text-md hover:text-gray-50">Blog</Link>
                                            </li> */}
                                            <li>
                                                <Link to="/terms-of-service/#top" smooth className="leading-6 text-gray-300 text-md hover:text-gray-50">
                                                    Conditions d'utilisation
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/privacy-policy/#top" smooth className="leading-6 text-gray-300 text-md hover:text-gray-50">
                                                    Politique de confidentialité
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="mt-10 md:mt-0">
                                        <h3 className="font-semibold leading-6 text-white text-md">Entreprise</h3>
                                        <ul role="list" className="mt-6 space-y-4">
                                            <li>
                                                <Link to="/about/#top" smooth className="leading-6 text-gray-300 text-md hover:text-gray-50">
                                                    À propos de nous
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/contact#top" smooth className="leading-6 text-gray-300 text-md hover:text-gray-50">
                                                    Contactez-nous
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pt-8 mt-16 border-t border-gray-400/30 sm:mt-20 lg:mt-24">
                            <div className="text-center text-white text-md">
                                Copyright © 2024 . Tous droits réserver par Congo Digital
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
