import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header.jsx'
import Footer from './components/Footer.jsx'
import Loader from './components/Loader'; // Importez votre Loader
import DigitalSkillsDevelopment from './screens/services/DigitalSkillsDevelopment.jsx';
import RecruitmentPromotion from './screens/services/RecruitmentPromotion.jsx';
import EcosystemStrengthening from './screens/services/EcosystemStrengthening.jsx';

// Lazy loading for routes
const AboutPage = lazy(() => import('./pages/AboutPage.jsx'));
const ContactPage = lazy(() => import('./pages/ContactPage.jsx'));
const HomePage = lazy(() => import('./pages/HomePage.jsx'));
const PortfolioPage = lazy(() => import('./pages/PortfolioPage.jsx'));
const ServicesPage = lazy(() => import('./pages/ServicesPage.jsx'));
const WebMobileDevelopment = lazy(() => import('./screens/services/WebMobileDevelopment.jsx'));
const NetworkInfrastructure = lazy(() => import('./screens/services/NetworkInfrastructure.jsx'));
const WebNetworkSecurity = lazy(() => import('./screens/services/WebNetworkSecurity.jsx'));
const DigitalMarketing = lazy(() => import('./screens/services/DigitalMarketing.jsx'));
const MaintenanceSupport = lazy(() => import('./screens/services/MaintenanceSupport.jsx'));
const DigitalArchiving = lazy(() => import('./screens/services/DigitalArchiving.jsx'));

function App() {
  return (
    <Router>
      <Header />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/services/web-mobile-development" element={<WebMobileDevelopment />} />
          <Route path="/services/network-infrastructure" element={<NetworkInfrastructure />} />
          <Route path="/services/web-network-security" element={<WebNetworkSecurity />} />
          <Route path="/services/digital-marketing" element={<DigitalMarketing />} />
          <Route path="/services/maintenance-support" element={<MaintenanceSupport />} />
          <Route path="/services/digital-archiving" element={<DigitalArchiving />} />
          <Route path="/services/digital-skills-development" element={<DigitalSkillsDevelopment />} />
          <Route path="/services/recruitment-promotion" element={<RecruitmentPromotion />} />
          <Route path="/services/ecosystem-strengthening" element={<EcosystemStrengthening />} />
          <Route path="/blog" element={<DigitalArchiving />} />
          <Route path="/terms-of-services" element={<DigitalArchiving />} />
          <Route path="/privacy-policy" element={<DigitalArchiving />} />
        </Routes>
      </Suspense>
      <Footer />
    </Router>
  );
}

export default App;
