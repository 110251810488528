import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faUsers, faLaptop } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import NavServices from './NavServices';

export default function EcosystemStrengthening() {
    return (
        <>
            {/* Hero Section */}
            <motion.div 
                className="relative flex items-center justify-center h-64 bg-center bg-cover" 
                style={{ backgroundImage: "url('https://example.com/ecosystem-strengthening.jpg')" }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <h1 className="relative text-4xl font-bold text-white">Renforcement de l’Écosystème Numérique</h1>
            </motion.div>

            {/* Service Overview Section */}
            <div className="container px-4 py-12 mx-auto">
                <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
                    <NavServices/>
                    <motion.div 
                        className="md:col-span-2 staggeredFadeInUp" 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <h2 className="text-3xl font-bold">Aperçu du Service</h2>
                        <p className="mt-4 text-gray-600">
                            Nous valorisons l'Incubation de Startups à Impact Social et l'organisation de Hackathons pour le Développement Durable. Nos solutions de Logiciels Web sur Mesure répondent aux besoins des entreprises, ONG et institutions, stimulant ainsi la croissance et l'efficacité opérationnelle. Nous travaillons à créer un écosystème numérique florissant qui soutient l'innovation et la collaboration.
                        </p>
                        <div className="grid grid-cols-1 gap-4 mt-8 md:grid-cols-2">
                            <img src="https://plus.unsplash.com/premium_photo-1681400690940-8eff232a8f7d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Incubation de Startups" className="object-cover w-full h-48 rounded-lg"/>
                            <img src="https://plus.unsplash.com/premium_photo-1681400690940-8eff232a8f7d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Hackathons" className="object-cover w-full h-48 rounded-lg"/>
                        </div>
                    </motion.div>
                </div>
            </div>

            {/* Service Type Section */}
            <div className="container px-4 py-12 mx-auto">
                <h2 className="text-3xl font-bold text-center">Types de Services</h2>
                <div className="grid grid-cols-1 gap-8 mt-8 md:grid-cols-2">
                    <motion.div 
                        className="flex items-center p-6 bg-white rounded-lg shadow-lg staggeredFadeInUp" 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <FontAwesomeIcon icon={faLightbulb} size="2x" className="text-first-color"/>
                        <div className="ml-4">
                            <h3 className="text-xl font-bold">Incubation de Startups</h3>
                            <p className="text-gray-600">Soutien aux startups à impact social avec des ressources, des conseils et un accès aux investisseurs pour favoriser la croissance et le développement durable.</p>
                        </div>
                    </motion.div>
                    <motion.div 
                        className="flex items-center p-6 bg-white rounded-lg shadow-lg staggeredFadeInUp" 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <FontAwesomeIcon icon={faUsers} size="2x" className="text-first-color"/>
                        <div className="ml-4">
                            <h3 className="text-xl font-bold">Organisation de Hackathons</h3>
                            <p className="text-gray-600">Événements collaboratifs où les innovateurs se réunissent pour créer des solutions technologiques aux défis sociaux et environnementaux.</p>
                        </div>
                    </motion.div>
                    <motion.div 
                        className="flex items-center p-6 bg-white rounded-lg shadow-lg staggeredFadeInUp" 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <FontAwesomeIcon icon={faLaptop} size="2x" className="text-first-color"/>
                        <div className="ml-4">
                            <h3 className="text-xl font-bold">Solutions de Logiciels Web sur Mesure</h3>
                            <p className="text-gray-600">Développement de logiciels personnalisés pour répondre aux besoins spécifiques des entreprises, ONG et institutions, optimisant ainsi leurs opérations.</p>
                        </div>
                    </motion.div>
                </div>
            </div>

            {/* FAQ Section */}
            <div className="container px-4 py-12 mx-auto">
                <h2 className="text-3xl font-bold text-center">Questions Fréquemment Posées</h2>
                <div className="mt-8 space-y-4">
                    <motion.div className="p-4 bg-white rounded-lg shadow-lg staggeredFadeInUp" whileHover={{ scale: 1.01 }}>
                        <h3 className="text-xl font-bold text-first-color">Qu'est-ce que l'incubation de startups implique ?</h3>
                        <p className="mt-2 text-gray-600">L'incubation de startups inclut le mentorat, l'accès aux ressources et aux réseaux d'investisseurs, et des ateliers pour aider les startups à se développer de manière durable.</p>
                    </motion.div>
                    <motion.div className="p-4 bg-white rounded-lg shadow-lg staggeredFadeInUp" whileHover={{ scale: 1.01 }}>
                        <h3 className="text-xl font-bold text-first-color">Comment puis-je participer à un hackathon ?</h3>
                        <p className="mt-2 text-gray-600">Nos hackathons sont ouverts à tous ceux qui souhaitent innover pour le bien social. Les inscriptions sont disponibles sur notre site web ou via nos partenaires.</p> 
                    </motion.div>
                    <motion.div className="p-4 bg-white rounded-lg shadow-lg staggeredFadeInUp" whileHover={{ scale: 1.01 }}>
                        <h3 className="text-xl font-bold text-first-color">Offrez-vous des solutions logicielles personnalisées ?</h3>
                        <p className="mt-2 text-gray-600">Oui, nous développons des logiciels sur mesure pour répondre aux besoins spécifiques de chaque client, en optimisant leurs processus et leur efficacité.</p>
                    </motion.div>
                </div>
            </div>
        </>
    );
}
