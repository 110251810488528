import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faUserTie, faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import NavServices from './NavServices';

export default function RecruitmentPromotion() {
    return (
        <>
            {/* Hero Section */}
            <motion.div 
                className="relative flex items-center justify-center h-64 bg-center bg-cover" 
                style={{ backgroundImage: "url('https://example.com/recrutement-promotion.jpg')" }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <h1 className="relative text-4xl font-bold text-white">Recrutement et Promotion des Emplois dans le Numérique</h1>
            </motion.div>

            {/* Service Overview Section */}
            <div className="container px-4 py-12 mx-auto">
                <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
                    <NavServices/>
                    <motion.div 
                        className="md:col-span-2 staggeredFadeInUp" 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <h2 className="text-3xl font-bold">Aperçu du Service</h2>
                        <p className="mt-4 text-gray-600">
                            Avec la plateforme Congo Digital JoB et des initiatives comme les Programmes d'Incubation de Talents, nous visons à dynamiser le secteur de l'emploi numérique au Congo. Nous offrons des solutions innovantes pour le recrutement, la formation et la promotion des talents dans le domaine numérique, aidant les entreprises à trouver les compétences dont elles ont besoin pour prospérer dans une économie de plus en plus numérique.
                        </p>
                        <div className="grid grid-cols-1 gap-4 mt-8 md:grid-cols-2">
                            <img src="https://plus.unsplash.com/premium_photo-1681400690940-8eff232a8f7d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Recrutement Numérique" className="object-cover w-full h-48 rounded-lg"/>
                            <img src="https://plus.unsplash.com/premium_photo-1681400690940-8eff232a8f7d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Promotion des Talents" className="object-cover w-full h-48 rounded-lg"/>
                        </div>
                    </motion.div>
                </div>
            </div>

            {/* Service Type Section */}
            <div className="container px-4 py-12 mx-auto">
                <h2 className="text-3xl font-bold text-center">Types de Services</h2>
                <div className="grid grid-cols-1 gap-8 mt-8 md:grid-cols-2">
                    <motion.div 
                        className="flex items-center p-6 bg-white rounded-lg shadow-lg staggeredFadeInUp" 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <FontAwesomeIcon icon={faBriefcase} size="2x" className="text-first-color"/>
                        <div className="ml-4">
                            <h3 className="text-xl font-bold">Plateforme de Recrutement</h3>
                            <p className="text-gray-600">Une plateforme dédiée au recrutement des talents numériques, facilitant la mise en relation entre les entreprises et les professionnels du numérique.</p>
                        </div>
                    </motion.div>
                    <motion.div 
                        className="flex items-center p-6 bg-white rounded-lg shadow-lg staggeredFadeInUp" 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <FontAwesomeIcon icon={faUserTie} size="2x" className="text-first-color"/>
                        <div className="ml-4">
                            <h3 className="text-xl font-bold">Programmes d'Incubation de Talents</h3>
                            <p className="text-gray-600">Des programmes pour identifier, former et promouvoir les talents numériques, en les aidant à développer leurs compétences et à se préparer pour le marché du travail.</p>
                        </div>
                    </motion.div>
                    <motion.div 
                        className="flex items-center p-6 bg-white rounded-lg shadow-lg staggeredFadeInUp" 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <FontAwesomeIcon icon={faNetworkWired} size="2x" className="text-first-color"/>
                        <div className="ml-4">
                            <h3 className="text-xl font-bold">Événements de Networking</h3>
                            <p className="text-gray-600">Organisation d'événements pour faciliter les connexions entre les professionnels du numérique et les employeurs, favorisant ainsi les opportunités d'emploi et de collaboration.</p>
                        </div>
                    </motion.div>
                </div>
            </div>

            {/* FAQ Section */}
            <div className="container px-4 py-12 mx-auto">
                <h2 className="text-3xl font-bold text-center">Questions Fréquemment Posées</h2>
                <div className="mt-8 space-y-4">
                    <motion.div className="p-4 bg-white rounded-lg shadow-lg staggeredFadeInUp" whileHover={{ scale: 1.01 }}>
                        <h3 className="text-xl font-bold text-first-color">Comment fonctionne la plateforme Congo Digital JoB ?</h3>
                        <p className="mt-2 text-gray-600">Congo Digital JoB est une plateforme de recrutement spécialisée qui permet aux entreprises de publier des offres d'emploi et aux candidats de postuler en ligne. Elle inclut des outils pour faciliter le processus de recrutement.</p>
                    </motion.div>
                    <motion.div className="p-4 bg-white rounded-lg shadow-lg staggeredFadeInUp" whileHover={{ scale: 1.01 }}>
                        <h3 className="text-xl font-bold text-first-color">Quels sont les avantages des programmes d'incubation de talents ?</h3>
                        <p className="mt-2 text-gray-600">Ces programmes offrent un mentorat intensif, des ateliers et des ressources pour aider les talents à développer leurs compétences et à se connecter avec des employeurs potentiels.</p> 
                    </motion.div>
                    <motion.div className="p-4 bg-white rounded-lg shadow-lg staggeredFadeInUp" whileHover={{ scale: 1.01 }}>
                        <h3 className="text-xl font-bold text-first-color">Comment puis-je participer à vos événements de networking ?</h3>
                        <p className="mt-2 text-gray-600">Nos événements de networking sont ouverts aux professionnels du numérique et aux entreprises. Vous pouvez vous inscrire via notre site web ou en nous contactant directement.</p>
                    </motion.div>
                </div>
            </div>
        </>
    );
}
